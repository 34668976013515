<template>
  <app-loading v-if="isLoading" />
  <div
    class="font-semibold text-3xl text-center text-gray-deep"
    v-else-if="isError"
  >
    Bedankt dat je ons bezoekt. <br />
    Helaas is deze inhoud niet beschikbaar vanuit het land waarvan je ons
    bezoekt.
  </div>
  <router-view v-else />
</template>

<script>
import AppLoading from "@/components/app/AppLoading.vue";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  components: { AppLoading },
  name: "app",

  setup() {
    const store = useStore();
    const isLoading = ref(true);
    const isError = ref(false);

    const checkLocation = async () => {
      try {
        let res = await store.dispatch("auth/checkLocation");
        if (res.status === 401) {
          isError.value = true;
        }
      } catch (error) {
        isError.value = true;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      checkLocation();
    });

    return {
      isLoading,
      isError,
    };
  },
};
</script>
