const state = {
  error: {
    code: 0,
    enable: false,
    message: "",
  },
};

const mutations = {
  enable(state, payload) {
    state.error.code = payload.code;
    state.error.message = payload.message;
    state.error.enable = true;
  },
  disable(state) {
    state.error.code = 0;
    state.error.message = "";
    state.error.enable = false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
