import client from "../client";
import { getIncentiveHistoryUrl } from "../url";
import res from "./response";

export default {
  async getIncentiveHistory() {
    return res.getIncentiveHistory(
      await client.withAuth().post(getIncentiveHistoryUrl)
    );
  },
};
