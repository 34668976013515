export default {
  updatePassword(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  updateNumber(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  updateEmailRequest(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },
  updateEmail(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  getProfileBlocks(response) {
    let profileBlocks = {};
    profileBlocks.upToDatePercentage = response.upToDatePercentage;
    profileBlocks.hasOutOfDateBlocks = response.hasOutOfDateBlocks;
    profileBlocks.visibleBlocks = [];
    response.visibleBlocks.forEach((visibleBlock) => {
      profileBlocks.visibleBlocks.push({
        alias: visibleBlock.alias,
        obsolete: visibleBlock.obsolete,
        updateLink: visibleBlock.updateLink,
        points: visibleBlock.points,
        imageFile: visibleBlock.imageFile,
      });
    });

    return profileBlocks;
  },

  getUnsubscribeLink(response) {
    return {
      unsubscribeLink: response.unsubscribeLink,
    };
  },

  getUserProfile(response) {
    return {
      firstName: response.firstName,
      lastName: response.lastName,
      gender: response.gender,
      email: response.email,
      mobile: response.mobile,
      ibanUrl: response.ibanUrl,
      profileUrl: response.profileUrl,
      landLine: response.landLine,
    };
  },
};
