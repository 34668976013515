// import Vue from "vue";
import { getCookie, setCookie, removeCookie } from "@/helper/cookies";

const state = {
  session: null,
  sessionExpire: false,
};

const actions = {
  getValueFromkey(_, key) {
    return getCookie(key);
  },
};

const mutations = {
  set(state, payload) {
    return setCookie(payload.key, payload.value);
  },
  setSession(state, payload) {
    state.session = payload;
  },
  removeAll() {
    removeCookie("session");
    removeCookie("firstName");
    removeCookie("lastName");
  },
  setSessionExpire(state, payload = true) {
    state.sessionExpire = payload;
  },
};

const getters = {
  getSession(state) {
    return state.session || getCookie("session");
  },
  getName() {
    return getCookie("firstName");
  },
  getInitialName() {
    let firstName = getCookie("firstName");
    let lastName = getCookie("lastName");
    let initialName = "";
    if (firstName) {
      initialName = initialName + firstName.charAt(0);
    }
    if (lastName) {
      initialName = initialName + lastName.charAt(0);
    }

    return initialName;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
