import { createStore } from "vuex";

import storage from "./module/storage";
import error from "./module/error";
import loading from "./module/loading";
import modal from "./module/modal";
import auth from "./module/auth";
import dashboard from "./module/dashboard";
import fees from "./module/fees";
import profile from "./module/profile";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    storage,
    error,
    loading,
    modal,
    auth,
    dashboard,
    fees,
    profile,
  },
});
