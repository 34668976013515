// import Vue from "vue";
import axios from "axios";
import errorRes from "./errorRes";
import store from "@/store";
import router from "@/router";

const baseurl = "https://mijn.mselect.nl/api";

const client = {
  auth: false,
  withAuth() {
    client.auth = true;
    return client;
  },
  withoutAuth() {
    client.auth = false;
    return client;
  },
};

axios.interceptors.request.use((config) => {
  store.commit("loading/enable");
  if (client.auth) {
    let token = store.getters["storage/getSession"];
    config.headers.Authorization = token;
  }
  return config;
});

axios.interceptors.response.use(
  (response) => {
    store.commit("loading/disable");
    return response;
  },
  (error) => {
    store.commit("loading/disable");
    if (error.response) {
      let errorResponse = errorRes.res(error.response.data);
      if (client.auth) {
        // Vue.$toast(errorResponse.message);
      }

      if (errorResponse.code === 403 || errorResponse.code === 498) {
        store.commit("storage/removeAll");
        store.commit("storage/setSessionExpire");
        // Vue.$toast(errorResponse.message);
        if (client.auth) {
          router.push("/");
        }
      }

      // else if (errorResponse.code === 400 || errorResponse.code === 500) {
      // store.commit("error/enable", errorResponse);
      // }
      throw errorResponse;
    } else {
      // Vue.$toast(error.message);
      throw error;
    }
  }
);

const types = ["get", "post", "put", "delete"];
types.forEach((verb) => {
  client[verb] = async (url, params = {}, data = {}, headers = {}) => {
    return await axios({
      method: verb,
      url: baseurl + url,
      params,
      data,
      headers: headers,
    })
      .then((response) => {
        client.auth = false;
        return response.data;
      })
      .catch((error) => {
        client.auth = false;
        throw error;
      });
  };
});

export default client;
