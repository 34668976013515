import client from "../client";
import {
  loginUrl,
  logoutUrl,
  sendResetMailUrl,
  checkResetTokenUrl,
  passwordResetUrl,
  checkLocationUrl,
} from "../url";
import req from "./request";
import res from "./response";

export default {
  async checkLocation() {
    return res.checkLocation(
      await client.withoutAuth().post(checkLocationUrl, {}, {})
    );
  },

  async doLogin(user) {
    return res.doLogin(
      await client.withoutAuth().post(loginUrl, {}, req.doLogin(user))
    );
  },

  async doLogout(session) {
    return res.doLogout(
      await client.withoutAuth().post(logoutUrl, {}, req.doLogout(session))
    );
  },

  async sendResetMail(user) {
    return res.sendResetMail(
      await client
        .withoutAuth()
        .post(sendResetMailUrl, {}, req.sendResetMail(user))
    );
  },

  async checkResetToken(token) {
    return res.checkResetToken(
      await client
        .withoutAuth()
        .post(checkResetTokenUrl, {}, req.checkResetToken(token))
    );
  },

  async passwordReset(user) {
    return res.passwordReset(
      await client
        .withoutAuth()
        .post(passwordResetUrl, {}, req.passwordReset(user))
    );
  },
};
