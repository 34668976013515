export default {
  checkLocation(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  doLogin(response) {
    return {
      firstName: response.firstName,
      lastName: response.lastName,
      session: response.session,
    };
  },

  doLogout(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  sendResetMail(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  checkResetToken(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },

  passwordReset(response) {
    return {
      status: response.status,
      message: response.message,
    };
  },
};
