import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:pathMatch(.*)*",
    component: () =>
      import(/* webpackChunkName: "404" */ "../layouts/InValidURL.vue"),
  },
  {
    path: "/",
    component: () =>
      import(/* webpackChunkName: "Before Auth" */ "../layouts/BeforeAuth.vue"),
    children: [
      {
        path: "/",
        name: "Login",
        component: () =>
          import(/* webpackChunkName: "Login" */ "../views/Login.vue"),
      },
      {
        path: "/forgotpassword",
        name: "ForgotPassword",
        component: () =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "../views/ForgotPassword.vue"
          ),
      },
      {
        path: "/resetpassword",
        name: "ResetPassword",
        component: () =>
          import(
            /* webpackChunkName: "ForgotPassword" */ "../views/ResetPassword.vue"
          ),
      },
    ],
  },
  {
    path: "/dashboard",
    component: () =>
      import(/* webpackChunkName: "After Auth" */ "../layouts/AfterAuth.vue"),
    redirect: "/dashboard/onderzoeken",
    children: [
      {
        path: "/dashboard/:tab",
        name: "Dashboard",
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ "../views/Dashboard.vue"),
      },
      // {
      //   path: "/vergoedingen",
      //   name: "Vergoedingen",
      //   component: () =>
      //     import(/* webpackChunkName: "Fees" */ "../views/Fees.vue"),
      // },
      {
        path: "/helpdesk",
        name: "Helpdesk",
        component: () =>
          import(/* webpackChunkName: "HelpDesk" */ "../views/HelpDesk.vue"),
      },
      {
        path: "/profiel",
        name: "Profiel",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "../views/Profile.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
