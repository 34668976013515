import profile from "@/api/profile/index";

const actions = {
  async updatePassword(state, passwords) {
    try {
      return await profile.updatePassword(passwords);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async updateNumber(state, number) {
    try {
      return await profile.updateNumber(number);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async updateLandlineNumber(state, number) {
    try {
      return await profile.updateLandlineNumber(number);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async updateEmailRequest(state, email) {
    try {
      return await profile.updateEmailRequest(email);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async updateEmail(state, pincode) {
    try {
      return await profile.updateEmail(pincode);
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getProfileBlocks() {
    try {
      return await profile.getProfileBlocks();
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getUnsubscribeLink() {
    try {
      return await profile.getUnsubscribeLink();
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getUserProfile() {
    try {
      return await profile.getUserProfile();
    } catch (error) {
      throw new Error(error.message);
    }
  },
};

export default {
  namespaced: true,
  actions,
};
