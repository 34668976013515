import client from "../client";
import req from "./request";
import res from "./response";
import {
  updatePasswordUrl,
  updateNumberUrl,
  updateEmailRequestUrl,
  updateEmailUrl,
  getProfileBlocksUrl,
  getUnsubscribeLinkUrl,
  getUserProfileUrl,
} from "../url";

export default {
  async updatePassword(password) {
    return res.updatePassword(
      await client
        .withAuth()
        .post(updatePasswordUrl, {}, req.updatePassword(password))
    );
  },

  async updateNumber(number) {
    return res.updateNumber(
      await client
        .withAuth()
        .post(updateNumberUrl, {}, req.updateNumber(number))
    );
  },

  async updateEmailRequest(email) {
    return res.updateEmailRequest(
      await client
        .withAuth()
        .post(updateEmailRequestUrl, {}, req.updateEmailRequest(email))
    );
  },
  async updateEmail(pincode) {
    return res.updateEmail(
      await client.withAuth().post(updateEmailUrl, {}, req.updateEmail(pincode))
    );
  },
  async getProfileBlocks() {
    return res.getProfileBlocks(
      await client.withAuth().post(getProfileBlocksUrl)
    );
  },
  async getUnsubscribeLink() {
    return res.getUnsubscribeLink(
      await client.withAuth().post(getUnsubscribeLinkUrl)
    );
  },
  async getUserProfile() {
    return res.getUserProfile(await client.withAuth().post(getUserProfileUrl));
  },
};
