import client from "../client";
import {
  getCurrentRegistrationUrl,
  getScheduledAppointmentsUrl,
  getResearchesUrl,
  GetResearchOptionsUrl,
} from "../url";
import res from "./response";

export default {
  async getCurrentRegistration() {
    return res.getCurrentRegistration(
      await client.withAuth().post(getCurrentRegistrationUrl)
    );
  },

  async getScheduledAppointments() {
    return res.getScheduledAppointments(
      await client.withAuth().post(getScheduledAppointmentsUrl)
    );
  },

  async getResearches() {
    return res.getResearches(await client.withAuth().post(getResearchesUrl));
  },

  async getResearchOptionsUrl() {
    return res.getResearchOptionsUrl(
      await client.withAuth().post(GetResearchOptionsUrl)
    );
  },
};
