const state = {
  loading: [],
};

const mutations = {
  enable(state) {
    state.loading.push(true);
  },
  disable(state) {
    state.loading.pop();
  },
};

const getters = {
  getLoadingState() {
    if (state.loading.length > 0) {
      return true;
    }
    return false;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
};
