import dashboard from "@/api/dashboard/index";

const actions = {
  async getCurrentRegistration() {
    try {
      let registrations = await dashboard.getCurrentRegistration();
      return { data: registrations };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getScheduledAppointments({ dispatch }) {
    try {
      let appointments = await dashboard.getScheduledAppointments();
      let appointmentsCount = await dispatch("getNewCount", {
        length: appointments.length,
        key: "Agenda",
      });
      return { data: appointments, count: appointmentsCount };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getResearches({ dispatch }) {
    try {
      let researches = await dashboard.getResearches();
      let researchesCount = await dispatch("getNewCount", {
        length: researches.length,
        key: "Onderzoeken",
      });
      return { data: researches, count: researchesCount };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getResearchOptionsUrl() {
    try {
      let researchesOptions = await dashboard.getResearchOptionsUrl();
      return { data: researchesOptions };
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async getNewCount({ dispatch, commit }, payload) {
    let oldLength = await dispatch("storage/getValueFromkey", payload.key, {
      root: true,
    });
    await commit(
      "storage/set",
      { key: payload.key, value: payload.length },
      { root: true }
    );
    if (oldLength) {
      return (payload.length - oldLength).toString();
    }
    return payload.length.toString();
  },
};

export default {
  namespaced: true,
  actions,
};
