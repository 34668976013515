export default {
  getCurrentRegistration(registrations) {
    let response = [];
    registrations.forEach((registration) => {
      response.push({
        title: registration.title,
        when: registration.when,
        description: registration.description,
        location: registration.location,
        incentive: registration.incentive,
        unsubscribeLink: registration.unsubscribeLink,
        shareLink: registration.shareLink,
      });
    });
    return response;
  },

  getScheduledAppointments(appointments) {
    let response = [];
    appointments.forEach((appointment) => {
      response.push({
        title: appointment.title,
        when: appointment.when,
        description: appointment.description,
        location: appointment.location,
        incentive: appointment.incentive,
      });
    });
    return response;
  },

  getResearches(researches) {
    let response = [];

    researches.forEach((research) => {
      response.push({
        title: research.title,
        when: research.when,
        description: research.description,
        location: research.location,
        incentive: research.incentive,
        status: research.status,
        participationLink: research.participationLink,
        shareLink: research.shareLink,
        unsubscribeLink: research.unsubscribeLink,
        share: research.share,
      });
    });

    return response;
  },

  getResearchOptionsUrl(response) {
    return {
      url: response.researchOptionsUrl,
    };
  },
};
