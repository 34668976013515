import { useCookie, VueCookieNext } from "vue-cookie-next";

const setCookie = (key, value) => {
  VueCookieNext.setCookie(key, value);
};

const getCookie = (key) => {
  return VueCookieNext.getCookie(key);
};

const removeCookie = (key) => {
  return VueCookieNext.removeCookie(key);
};

const keys = () => {
  return VueCookieNext.keys();
};

const isCookieAvailable = (key) => {
  return VueCookieNext.isCookieAvailable(key);
};

export { setCookie, getCookie, removeCookie, keys, isCookieAvailable };
