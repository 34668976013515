export default {
  updatePassword(passwords) {
    return {
      oldPassword: passwords.old,
      newPassword: passwords.new,
    };
  },

  updateNumber(numbers) {
    return {
      MobileNumber: numbers.mobileNumber,
      landLineNumber: numbers.landline,
    };
  },

  updateEmailRequest(email) {
    return {
      password: email.password,
      currentEmailAddress: email.current,
      newEmailAddress: email.new,
    };
  },
  updateEmail(pincode) {
    return {
      pincode: pincode,
    };
  },
};
