export default {
  getIncentiveHistory(incentives) {
    let response = [];
    incentives.forEach((incentive) => {
      response.push({
        title: incentive.title,
        when: incentive.when,
        description: incentive.description,
        incentive: incentive.incentive,
        status: incentive.status,
      });
    });
    return response;
  },
};
