const state = {
  modalVisiable: false,
};

const mutations = {
  changeModalVisiablityStatus(state, val) {
    state.modalVisiable = val;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
};
