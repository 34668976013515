export default {
  doLogin(user) {
    return {
      username: user.email,
      password: user.password,
      panelcode: "ms",
    };
  },

  doLogout(session) {
    return {
      sessionid: session,
    };
  },

  sendResetMail(user) {
    return {
      emailAddress: user.email,
      panelcode: "ms",
    };
  },

  checkResetToken(token) {
    return {
      resetToken: token,
    };
  },

  passwordReset(user) {
    return {
      resetToken: user.token,
      password: user.password,
    };
  },
};
