<template>
  <div class="loading">
    <img src="@/assets/img/loading.gif" alt="loader" />
  </div>
</template>

<script>
export default {
  name: "AppLoading",
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.2);
  z-index: 99999;

  & img {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10%;
  }
}
</style>
